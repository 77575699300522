.nav {
    padding: 16px;
    z-index: 5;
    background-color: #181A20;
    color: $whiteColor;

    &-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_logo {
        position: relative;
        width: 150px;
        height: 60px;

    }

    &_links {
        width: 70%;
        display: none;

        ul {
            display: flex;

            li {
                margin: 0 2rem;
                text-transform: capitalize;
                transition: $generalTransition;
                cursor: pointer;

                &:first-child {
                    margin-left: 0;
                }

                &:hover {
                    border-bottom: 1px solid $whiteColor;
                    transition: $generalTransition;
                }
            }

        }
    }

    .hambugger {
        @include flexCC;

        .icon {
            width: 25px;
            height: 25px;
        }
    }
}


.mobile-nav-container {
    position: fixed;
    height: 100vh;
    width: 100%;
    top: 0;
    transform: translateX(100%);
    transition: $generalTransition;
    z-index: 88;
    display: flex;
    justify-content: flex-end;

    .mobile_links {
        background-color: rgb(30, 35, 41);
        width: 98%;

        .close-btn {
            display: flex;
            justify-content: flex-end;
            padding: 24px 16px;

            .icon {
                font-size: 24px;
            }
        }

        ul {
            padding: 16px;

            li {
                font-weight: 700;
                font-size: 30px;
                line-height: 31px;
                width: 100%;
                display: inline-block;
                text-transform: capitalize;
                margin-bottom: 25px;
            }
        }

        &_btns {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            padding: 16px;

            button {
                margin: 0.5rem;
                width: 100%;
                margin: 6px 0;
            }
        }

    }

    &.show {
        transform: translateX(0);
        background-color: rgba(0, 0, 0, 0.1);
    }
}



@include for-phone-only {}

@include for-tablet-potrait-up {
    .mobile-nav-container {
        .mobile_links {
            width: 60%;

            ul {
                li {
                    font-size: 35px;
                    margin-bottom: 45px;
                }
            }

            &_btns {
                margin-top: 60px;
                width: 100%;

                button {
                    width: 100%;
                    font-size: 1.2rem;
                    margin: 16px 0;
                }
            }
        }
    }
}

@include for-tablet-landscape-up {}

@include for-desktop-up {
    .nav {
        padding: 20px 16px;

        &_logo {
            width: auto;
            height: 70px;
        }

        &_links {
            @include flexCB;

            &_btns {

                button {
                    margin: 0 0.5rem;
                }
            }
        }

        .hambugger {
            display: none;
        }
    }

    .mobile-nav-container {
        display: none;
    }
}

@include for-big-desktop-up {}